import React, { useEffect, useState } from 'react';
import Button from '../../common/Button/Button';
import TeamEdit from './TeamEdit';
import TeamAdd from './TeamAdd';
import TeamRemove from './TeamRemove';
import TeamMember from './TeamMember';
import { Link } from "react-router-dom";
import { instanceFetch } from "../../utils/instanceFetch";
import { middlewareUrl } from "../../services/config";
import authHeader from "../../utils/authHeader";
import { decode } from "base64-arraybuffer";
import { signaturePdf, signaturePdfMeta } from "../../assets/files/signPdf";
import ModalWrapper from "../../common/Modal/ModalWrapper";

export const roles = ['Перегляд і завантаження', 'Редагування'];

const Team = () => {
  const [isModalEditVisible, setModalEditVisible] = useState(false);
  const [isModalAddVisible, setModalAddVisible] = useState(false);
  const [isModalRemoveVisible, setModalRemoveVisible] = useState(false);
  const [teamArray, setTeamArray] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);

  const [isVisiblePopup, setVisiblePopup] = useState(false);
  const [isVisibleSignature, setVisibleSignature] = useState(false);
  const [signError, setSignError] = useState('');

  const handleModalVisible = () => {
    setVisiblePopup(!isVisiblePopup);
  };

  useEffect(() => {
    if (isVisiblePopup) {
      const uint = new Uint8Array(decode(signaturePdf));
      const asBase64String = true;
      const signAlgo = EndUser.SignAlgo.DSTU4145WithGOST34311;
      const signType = EndUser.SignType.CAdES_T;

      /*function base64Decode(str) {
        var str = window.atob(str);
        var len = str.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
          bytes[i] = str.charCodeAt(i);
        }
        return bytes;
      }*/

      const onConfirmKSPOperation = (event) => {
        var node = '';
        node += '<a href="' + event.url + '">';
        node +=
          '<img src="data:image/bmp;base64,' +
          event.qrCode +
          '" style="padding: 10px; background: white;">';
        node += '</a>';

        document.getElementById('qr-code-block').innerHTML = node;
        document.getElementById('qr-code-block').style.display = 'block';
      };

      let euSign = new EndUser(
        'sign-widget-parent' /* Ідентифікатор батківського елементу */,
        'sign-widget' /* Ідентифікатор елементу iframe */,
        'https://eu.iit.com.ua/sign-widget/v20240301_new/' /* URI для завантаження iframe */,
        EndUser.FormType.ReadPKey /* Тип форми iframe */
      );

      setTimeout(() => {
        signDocument();
      }, 10000);
      const signDocument = () => {
        euSign
          .ReadPrivateKey()
          .then((res) => {
            return euSign.AddEventListener(
              EndUser.EventType.ConfirmKSPOperation,
              onConfirmKSPOperation
            );
          })
          .then(() => {
            euSign
              .SignData(uint, false, asBase64String, signAlgo, null, signType)
              .then((sign) => {
                instanceFetch
                  .post(
                    `${middlewareUrl}/b2b/account-company/store`,
                    {
                      company: sign
                    },
                    authHeader()
                  )
                  .then((res) => {
                    // console.log(res);
                    handleModalVisible();
                    setTimeout(() => {
                      window.location.reload();
                    }, 100);
                  });

                document.getElementById('qr-code-block').style.display = 'none';
                // document.getElementById('textAreaSign').value = sign;
              })
              .catch((e) => {
                document.getElementById('qr-code-block').style.display = 'none';
                console.log(
                  'Виникла помилка при підписі даних. ' + 'Опис помилки: ' + (e.message || e)
                );
                setSignError(
                  'Виникла помилка при підписі даних. ' + 'Опис помилки: ' + (e.message || e)
                );
              });
          })
          .catch(function (e) {
            console.log(
              'Виникла помилка при зчитуванні ос. ключа. ' + 'Опис помилки: ' + (e.message || e)
            );
          });
      };
    }
  }, [isVisiblePopup]);

  useEffect(() => {
    instanceFetch.get(`${middlewareUrl}/user/info`, authHeader())
      .then(response => setTeamArray(response.accountCompanies));
  }, []);

  const handleEditModal = (member) => {
    setModalEditVisible(!isModalEditVisible);
    setSelectedMember(member);
  };
  const handleAddModal = (member) => {
    setModalAddVisible(!isModalAddVisible);
    setSelectedMember(member);
  };
  const handleRemoveModal = (member) => {
    setModalRemoveVisible(!isModalRemoveVisible);
    setSelectedMember(member);
  };

  return (
    <>
      <div className="profile">
        <div className="tab-label-list">
          <Link to="/profile/my-profile" className="tab-label primary">
            <span className="tab-label__text">Мій профіль</span>
          </Link>
          <Link to="/profile/change-password" className="tab-label primary">
            <span className="tab-label__text">Змінити пароль</span>
          </Link>
          <div className="tab-label primary active">
            <span className="tab-label__text">Мої компанії</span>
          </div>
          <Link to="/profile/notifications" className="tab-label primary">
            <span className="tab-label__text">Сповіщення</span>
          </Link>
        </div>
        <div className="profile-tab-content">
          <div className="my-team">

            {!isVisibleSignature &&
              teamArray.map((item) => (
                <TeamMember
                  key={item.id}
                  member={item}
                  handleRemoveModal={handleRemoveModal}
                  handleEditModal={handleEditModal}
                />
              ))
            }
            {/*{teamArray.map((item) => (
              <TeamMember
                key={item.id}
                member={item}
                handleRemoveModal={handleRemoveModal}
                handleEditModal={handleEditModal}
              />
            ))}*/}

            {isVisibleSignature ? (
                <div className="box-shadow box-shadow-padding item-rounded">
                  <div className="documents-signature">
                    <div
                      className="button-back"
                      onClick={() => setVisibleSignature(!isVisibleSignature)}
                    >
                      <span><img src="/icons/arrow-back.svg" /></span>
                      <span>Повернутсь назад</span>
                    </div>
                    <div className="documents-signature-header">
                      <div className="documents-signature-header__title">Заява на приєднання</div>
                      <div className="documents-signature-header__buttons">
                        <Button
                          type="primary"
                          typeHtml="submit"
                          text="Підписати через КЕП"
                          onClick={() => {
                            setVisiblePopup(!isVisiblePopup);
                          }}
                        />
                      </div>
                    </div>
                    <div className="documents-signature__iframe">
                      <iframe
                        src={signaturePdfMeta + signaturePdf}
                        width="100%"
                        height="532"
                        frameBorder="0"
                      />
                    </div>
                  </div>
                </div>
            ) : (
              <div className="box-shadow box-shadow-padding item-rounded">
                <div className="box-row">
                  <div className="box-row-text">
                    <div className="box-shadow-title">Додати нову компанію</div>
                    <div className="box-shadow-sub-title">
                      Додайте ще одну компанію, підписавши заяву на приєднання
                    </div>
                  </div>
                  <Button type="outline-secondary" onClick={() => setVisibleSignature(!isVisibleSignature)}>
                    <span>
                      <img src="/icons/plus-black.svg" />
                    </span>
                    <span>Додати бізнес</span>
                  </Button>
                </div>
              </div>
            )}

            <TeamAdd
              isModalAddVisible={isModalAddVisible}
              handleAddModal={handleAddModal}
              member={selectedMember}
            />
            <TeamEdit
              isModalEditVisible={isModalEditVisible}
              handleEditModal={handleEditModal}
              member={selectedMember}
            />
            <TeamRemove
              isModalRemoveVisible={isModalRemoveVisible}
              handleRemoveModal={handleRemoveModal}
              member={selectedMember}
            />
          </div>
        </div>
      </div>

      {isVisiblePopup && (
        <ModalWrapper
          className="add-iit-form"
          isModalVisible={isVisiblePopup}
          handleClick={handleModalVisible}
          closeIcon={<img src="/icons/modal-close-black.svg" />}
        >
          <div id="sign-widget-parent" className="iit-widget"></div>
          <div id="qr-code-block"></div>
          <textarea id="textAreaData"></textarea>
          <div className="sign-modal-error">{signError}</div>
        </ModalWrapper>
      )}
    </>
  );
};

export default Team;
