import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import SidebarSupport from './SidebarSupport';
import cx from 'classnames';
import { connect } from 'react-redux';

const handleClick = (e, disabled) => {
  if (disabled) e.preventDefault();
};

const Sidebar = ({ type, userInfo, data = {} }) => {
  const { pathname } = useLocation();
  const getPath = pathname.split('/')[1];
  const {
    hiddenLinks,
    handleCloseMenu,
    withMenu,
    checkActive,
    activeGasB2b,
    activeEnergiyaB2b,
    isB2b,
    isEnergy
  } = data

  const nav = [
    {
      link: `/${getPath}/dashboard`,
      icon: 'dashboard',
      name: 'Аналітика',
    },
    {
      link: `/${getPath}/order`,
      icon: 'fire',
      name: type === 'gas' ? 'Замовити газ' : 'Замовити електроенергію',
      disabled: true
    },
    {
      link: `/${getPath}/new-invoice`,
      icon: 'new-invoice',
      name: 'Сформувати рахунок',
    },
    {
      link: `/${getPath}/documents`,
      icon: 'documents',
      name: 'Документи',
    },
  ];

  return (
    <aside className="sidebar">
      {/*<div className="sidebar__organization">{type=== 'gas' ? `ТОВ "Промпостач"` : `Товариство з обмеженою відповідальністю "ЛАВАНДА" `}</div>*/}

      <div className="sidebar__nav">
        {nav.map(({ link, icon, name, disabled = !userInfo.gasB2b }, index) => (
          <NavLink
            onClick={(e) => {
              handleClick(e, disabled);
              withMenu && handleCloseMenu()
            }}
            to={link}
            className={cx('sidebar-link', { disabled })}
            key={index}
          >
            <div className="sidebar-link__box">
              <img src={`/icons/${icon}.svg`} alt={icon} />
            </div>

            <span className="nav-unskew">{name}</span>
          </NavLink>
        ))}
      </div>

      {hiddenLinks ? null : (
        <div className="sidebar__toggle">
          {((activeGasB2b && activeEnergiyaB2b && isB2b) || isEnergy) && (
            <div className="header__col center">
              {activeGasB2b && (
                <NavLink
                  onClick={(e) => {
                    handleClick(e, !activeGasB2b);
                    withMenu && handleCloseMenu()

                  }}
                  disabled={!activeGasB2b}
                  isActive={() => checkActive('gas')}
                  to="/gas-b2b/dashboard"
                  className="header-tab"
                >
                  Постачання газу
                </NavLink>
              )}

              {activeEnergiyaB2b && (
                <NavLink
                  onClick={(e) => {
                    handleClick(e, !activeEnergiyaB2b);
                    withMenu && handleCloseMenu()
                  }}
                  disabled={!activeEnergiyaB2b}
                  isActive={() => checkActive('energiya')}
                  to="/energiya/dashboard"
                  className="header-tab header-tab--energy"
                >
                  Електропостачання
                </NavLink>
              )}
            </div>
          )}
        </div>
      )}

      <SidebarSupport />
    </aside>
  );
};

const mapStateToProps = (store) => {
  const { application } = store;
  return {
    userInfo: application.userInfo,
  };
};
export default connect(mapStateToProps)(Sidebar);
