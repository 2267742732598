import React from 'react';
import Button from '../../common/Button/Button';

const DocumentsContract = ({ tableData }) => {
  return (
    <div className="documents-contract">
      <div className="info-box-list">
        {tableData.map((item, index) => (
          <div className="info-blue-box" key={index}>
            <div className="contract-item-info">
              <span className="contract-item-title">{item.contract}</span>
              <span className="contract-item-sub-title">{item.date}</span>
            </div>
            {item.document}
            <a
              href={item.link}
              download={item.title}
              className="download-receipt"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src="/icons/download.svg" />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DocumentsContract;
