import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DocumentsAdditionalAgreements from './DocumentsAdditionalAgreements';
import DocumentsSignature from './DocumentsSignature';
import { instanceFetch } from '../../utils/instanceFetch';
import { middlewareUrl } from '../../services/config';
import authHeader from '../../utils/authHeader';
import { Spin } from 'antd';
import EmptyDocuments from '../../components/EmptyData/EmptyDocuments';
import { Link } from 'react-router-dom';

const DocumentsGasSubcontracts = () => {
  const [item, setItem] = useState(false);
  const [selectedItemData, setSelectedItemData] = useState({});
  const [documentsArr, setDocumentsArr] = useState([]);
  const [loader, setLoader] = useState(true);
  const [dataIsEmpty, setDataIsEmpty] = useState(false);
  let document;

  const fetchDocs = async () => {
    try {
      const response = await instanceFetch.get(`${middlewareUrl}/user/info`, authHeader());
      // console.log('response', response);

      const companies = response.accountCompanies;
      const documentsPromises = companies.map(async (company) => {
        const documentsResponse = await instanceFetch.get(
          `${middlewareUrl}/b2b/electronic-documents/edrpou/${company.edrpou}`,
          authHeader()
        );
        return documentsResponse;
      });

      const documentsResponses = await Promise.all(documentsPromises);
      let mergedDocumentsResponse = [];
      documentsResponses.forEach((documentsResponse) => {
        mergedDocumentsResponse = mergedDocumentsResponse.concat(documentsResponse);
      });

      mergedDocumentsResponse.sort(function (a, b) {
        let dateA = new Date(
          a.counterparty_signature_date ? a.counterparty_signature_date : a.document_date
        );
        let dateB = new Date(
          b.counterparty_signature_date ? b.counterparty_signature_date : b.document_date
        );

        if (dateA > dateB) {
          return -1;
        }
        if (dateA < dateB) {
          return 1;
        }
        return 0;
      });

      if (mergedDocumentsResponse.length) {
        setDocumentsArr(mergedDocumentsResponse);
        setLoader(false);
      } else {
        setLoader(false);
        setDataIsEmpty(true);
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const fetchDocument = async (id) => {
    try {
      const response = await instanceFetch.get(
        `${middlewareUrl}/b2b/electronic-documents/${id}`,
        authHeader()
      );
      document = response;
    } catch (error) {
      console.error(error);
    }
  };

  const setSelectedItem = useCallback((value) => setItem(value), []);

  const additionalAgreementsValues = [52, 58, 59, 60, 61];

  const filteredAdditionalAgreements = useMemo(
    () => documentsArr.filter((val) => additionalAgreementsValues.includes(val.document_type)),
    [documentsArr, additionalAgreementsValues]
  );

  const arrDocumentsTab = {
    content: () =>
      tableAdditionalAgreements.length ? (
        <DocumentsAdditionalAgreements
          setSelectedItem={setSelectedItem}
          tableData={tableAdditionalAgreements}
        />
      ) : (
        <EmptyDocuments />
      ),
    // disabled: true
  };

  const tableAdditionalAgreements = filteredAdditionalAgreements.map((item) => {
    let button;
    // console.log(document.document_cancellation_note);
    // console.log(item.document_signing_status);
    switch (item.document_signing_status) {
      case 1:
        button = (
          <a
            href={item.document_link}
            download={item.title}
            className="button button--download"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src="/icons/download2.svg" />
            <span>Завантажити</span>
          </a>
        );
        break;
      case 2:
        button = (
          <button
            className="btn primary btn--sign"
            onClick={() => {
              setSelectedItem(true);
              fetchDocument(item.id).then(() => {
                setSelectedItemData({
                  itemId: item.id,
                  itemTitle: item.document_number,
                  itemFileUrl: item.document_link,
                  itemFileMeta: document.document_base64_meta,
                  itemFileData: document.document_base64_content,
                  itemFileStatus: item.document_signing_status,
                  documentLink: document.document_link,
                  documentSignTypeCades: document.hasOwnProperty('cadess_x_long') ? document.cadess_x_long : false,
                });
              });
            }}
          >
            <span>Підписати</span>
          </button>
        );
        break;
      case 3:
        button = (
          <button
            className="button-label-complete button-label-complete--sign button-label-complete--first"
            onClick={() => {
              setSelectedItem(true);
              fetchDocument(item.id).then(() => {
                setSelectedItemData({
                  itemId: item.id,
                  itemTitle: item.document_number,
                  itemFileUrl: item.document_link,
                  itemFileMeta: document.document_base64_meta,
                  itemFileData: document.document_base64_content,
                  itemFileStatus: item.document_signing_status,
                  documentLink: document.document_link,
                  documentSignTypeCades: document.hasOwnProperty('cadess_x_long') ? document.cadess_x_long : false,
                  documentSessionClosed: document.session_closed,
                });
              });
            }}
          >
            <img src="/icons/checkmark_circle.svg" />
            <span>
              Підписано{' '}
              {new Date(
                item.counterparty_signature_date
                  ? item.counterparty_signature_date
                  : item.document_date
              )
                .toLocaleDateString()
                .replaceAll('/', '.')}
            </span>
          </button>
        );
        break;
      case 4:
        button = (
          <button
            className="button-label-complete button-label-complete--sign button-label-complete--first"
            onClick={() => {
              setSelectedItem(true);
              fetchDocument(item.id).then(() => {
                setSelectedItemData({
                  itemId: item.id,
                  itemTitle: item.document_number,
                  itemFileUrl: item.document_link,
                  itemFileMeta: document.document_base64_meta,
                  itemFileData: document.document_base64_content,
                  itemFileStatus: item.document_signing_status,
                  documentLink: document.document_link,
                  documentSignTypeCades: document.hasOwnProperty('cadess_x_long') ? document.cadess_x_long : false,
                });
              });
            }}
          >
            <img src="/icons/checkmark_circle.svg" />
            <span>
              Підписано{' '}
              {new Date(
                item.counterparty_signature_date
                  ? item.counterparty_signature_date
                  : item.document_date
              )
                .toLocaleDateString()
                .replaceAll('/', '.')}
            </span>
          </button>
        );
        break;
      case 5:
        button = (
          <button
            className="btn outline-primary btn--sign button-label-complete--right"
            onClick={() => {
              setSelectedItem(true);
              fetchDocument(item.id).then(() => {
                setSelectedItemData({
                  itemId: item.id,
                  itemTitle: item.document_number,
                  itemFileUrl: item.document_link,
                  itemFileMeta: document.document_base64_meta,
                  itemFileData: document.document_base64_content,
                  itemFileStatus: item.document_signing_status,
                  documentLink: document.document_link,
                  documentSignTypeCades: document.hasOwnProperty('cadess_x_long') ? document.cadess_x_long : false,
                  cancellationNote: document.document_cancellation_note
                });
              });
            }}
          >
            <span><img src="/icons/triangle-red.svg" /></span>
            <span>
              Запит на відхилення
            </span>
          </button>
        );
        break;
      case 7:
        button = (
          <button className="button-label-complete button-label-complete--sign button-label-complete--canceled">
            <img src="/icons/triangle-red.svg" />
            <span>
              Анульовано{' '}
              {item.document_canceled_date &&
                new Date(item.document_canceled_date).toLocaleDateString().replaceAll('/', '.')}
            </span>
          </button>
        );
        break;
      case 8:
        button = (
          <button className="button-label-complete button-label-complete--sign button-label-complete--rejected">
            <img src="/icons/triangle-info.svg" />
            <span>
              Відхилено клієнтом{' '}
              {item.document_rejected_date &&
                new Date(item.document_rejected_date).toLocaleDateString().replaceAll('/', '.')}
            </span>
          </button>
        );
        break;
    }

    return {
      id: item.id,
      date: new Date(
        item.counterparty_signature_date ? item.counterparty_signature_date : item.document_date
      )
        .toLocaleDateString()
        .replaceAll('/', '.'),
      title: item.document_number,
      contract: item.document_number,
      document_signing_status: item.document_signing_status,
      document: button,
    };
  });

  useEffect(() => {
    void fetchDocs();
  }, []);

  const Content = useMemo(() => {
    return arrDocumentsTab.content;
  }, [documentsArr, additionalAgreementsValues]);

  return (
    <div className="documents">
      {!item && (
        <div className="tab-label-list">
          <Link to="/gas-b2b/documents/contracts" className="tab-label primary">
            <span className="tab-label__text">Договори</span>
          </Link>
          <div className="tab-label primary active">
            <span className="tab-label__text">Додаткові угоди</span>
          </div>
          <Link to="/gas-b2b/documents/invoices" className="tab-label primary">
            <span className="tab-label__text">Рахунки</span>
          </Link>
          <Link to="/gas-b2b/documents/acts" className="tab-label primary">
            <span className="tab-label__text">Акти</span>
          </Link>
        </div>
      )}
      {!dataIsEmpty ? (
        <div className={`documents-tab-content box-shadow ${item ? 'selected' : ''}`}>
          <div className="documents-tab-content__wrap">
            {loader ? (
              <Spin />
            ) : (
              <div>
                {item ? (
                  <DocumentsSignature
                    setSelectedItem={setSelectedItem}
                    selectedItemData={selectedItemData}
                    fetchDocs={fetchDocs}
                    setLoader={setLoader}
                  />
                ) : (
                  <Content />
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="documents-tab-content box-shadow">
          <EmptyDocuments />
        </div>
      )}
    </div>
  );
};

export default DocumentsGasSubcontracts;
